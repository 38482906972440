.TextCursor {
  padding-left: 3px;
  color: #ffffff;
  padding-bottom: 6px;
}

.Pulse {
  animation: blink 0.5s infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
