.Footer {
  text-align: center;
  position: absolute;
  bottom: 0px;
  font-size: 32px;
  margin-bottom: 10px;
  width: 90%;
}

.Footer a {
  text-decoration: none;
  border-bottom: 1px solid #a2ef00;
  color: #a2ef00;
}

.Footer a:hover {
  text-decoration: none;
  border-bottom: 1px solid #cb0077;
  color: #cb0077;
}

@media only screen and (max-width: 320px) {
  .Footer {
    font-size: 24px;
    line-height: 1;
  }
}

@media only screen and (max-width: 800px) {
  .Footer {
    font-size: 24px;
    line-height: 1;
  }
}
