@font-face {
  font-family: "MyPhoneN1280Regular";
  src: url("./assets/my_phone_n1280-webfont.eot");
  src:
    url("./assets/my_phone_n1280-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/my_phone_n1280-webfont.woff") format("woff"),
    url("./assets/my_phone_n1280-webfont.ttf") format("truetype"),
    url("./assets/my_phone_n1280-webfont.svg#MyPhoneN1280Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "MyPhoneN1280Regular";
  margin: 0;
  background-color: #464646;
}
